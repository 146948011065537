html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh;
  position:relative;
}

* {
  box-sizing: border-box;
}

.form-group {
  margin-bottom: 0 !important;
}

div[role="group"][tabindex] {
  min-height: 100vh;
}

@font-face {
  font-family: AvenirNextLTPro-Bold;
  font-display: auto;
  src: url("../fonts/AvenirNextLTPro-Bold.otf") format("opentype");
}

@font-face {
  font-family: AvenirNextLTPro-Regular;
  font-display: auto;
  src: url("../fonts/AvenirNextLTPro-Regular.otf") format("opentype");
}

@font-face {
  font-family: Montserrat-Medium;
  font-display: auto;
  src: url("../fonts/Montserrat-Medium.ttf") format("opentype");
}

@font-face {
  font-family: Montserrat-Bold;
  font-display: auto;
  src: url("../fonts/Montserrat-Bold.ttf") format("opentype");
}
